function random (min, max) {
	return Math.random() * (max - min) + min;
}
function randomInt (min, max) {
	let rand = min + Math.random() * (max + 1 - min);
	rand = Math.floor(rand);
	return rand;
}

class Particle {
	constructor (canvas, color) {
		this.canvas = canvas;
		this.ctx = this.canvas.getContext("2d");
		this.color = color;
		this.x = randomInt(1, 2) === 1 ? random(0, 0.4) : random(0.6, 1);
		this.y = random(0.15, 0.7);
		this.speed = random(0.2, 0.95);
		this.angle = random(0, 360);
		this.radius = randomInt(4, 7);
		this.direction = {
			x: Math.cos(this.angle) * this.speed,
			y: Math.sin(this.angle) * this.speed,
		};
	}
	update () {
		this.border();
		const w = this.canvas.width;
		const h = this.canvas.height;
		const x = this.x * w;
		const y = this.y * h;
		this.x = (x + this.direction.x) / w;
		this.y = (y + this.direction.y) / h;
	}
	border () {
		const w = this.canvas.width;
		const h = this.canvas.height;
		let x = this.x * w;
		let y = this.y * h;

		const b = 30;

		if (x > w - this.radius + b) {
			x = w - this.radius + b;
			this.direction.x *= -1;
		}
		if (y > h - this.radius + b) {
			y = h - this.radius + b;
			this.direction.y *= -1;
		}
		if (x < this.radius - b) {
			x = this.radius - b;
			this.direction.x *= -1;
		}
		if (y < this.radius - b) {
			y = this.radius - b;
			this.direction.y *= -1;
		}
		this.x = x / w;
		this.y = y / h;
	}
	draw () {
		const x = this.x * this.canvas.width;
		const y = this.y * this.canvas.height;
		this.ctx.beginPath();
		this.ctx.arc(x, y, this.radius, 0, Math.PI * 2, false);
		this.ctx.fillStyle = this.color;
		this.ctx.fill();
		this.ctx.lineWidth = 1;
		this.ctx.strokeStyle = this.color;
		this.ctx.stroke();
	}
	render () {
		this.update();
		this.draw();
	}
}

class ParticlesRenderer {
	constructor (el, count = 20) {
		this.paused = true;
		this.colors = [
			"#97b3bc",
			"#38cea6",
			"#09aaa9",
			"#4642ba",
			"#e667c4",
			"#37b212",
			"#fa5255",
			// "#e4d7ff",
			"#a57ef7",
			"#6ac77f",
			// "#b69ad3",
			"#0084ff",
			"#3b5998",
			"#fa529c",
		].map((value) => ({value, cnt: 0}));
		this.circles = [];
		this.canvas = typeof el === "string" ? document.querySelector(el) : el;
		this.ctx = this.canvas.getContext("2d");
		for (let i = 0; i < count; i++) {
			this.circles.push(new Particle(this.canvas, this.getRandomColor()));
		}

		// this.updateSize();
		// this.start();
	}
	pause () {
		this.paused = true;
	}
	start () {
		if (this.paused) {
			this.paused = false;
			const loop = () => {
				if (!this.paused) {
					window.requestAnimationFrame(
						() => !this.paused && this.render(loop)
					);
				}
			};
			loop();
		}
	}
	render (callback) {
		callback && callback();
		if (document.hidden) {
			return;
		}
		this.ctx.fillStyle = "transparent";
		this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
		for (let i = 0; i < this.circles.length; i++) {
			this.circles[i].render();
		}
	}
	updateSize (width, height) {
		const pixelRatio = 1; // window.devicePixelRatio
		this.canvas.width =
			(width == null ? this.canvas.clientWidth : width) * pixelRatio;
		this.canvas.height =
			(height == null ? this.canvas.clientHeight : height) * pixelRatio;
		if (pixelRatio !== 1) {
			this.ctx.scale(pixelRatio, pixelRatio);
		}
	}
	getRandomColor () {
		const getIdx = () => Math.floor(Math.random() * this.colors.length);
		const min = this.colors.reduce((min, color) => {
			if ((color.cnt || 0) < min) {
				return color.cnt;
			}
			return min;
		}, Infinity);
		let color = this.colors[getIdx()];
		while ((color.cnt || 0) > min) {
			color = this.colors[getIdx()];
		}
		color.cnt = (color.cnt || 0) + 1;
		return color.value;
	}
}

export default ParticlesRenderer;
