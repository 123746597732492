import $ from "cash-dom";
import loadScript from "#app/util/load-script";

let callback = () => {};
let hideCallback = () => {};

export async function captchaX ({el, key, disabled = false} = {}) {
	if (!el) {
		el = document.querySelector(`#captcha-container`);
		if (!el) {
			el = document.createElement("div");
			el.id = "captcha-container";
			Object.assign(el.style, {
				position: "fixed",
				top: 0,
				left: 0,
				width: 0,
				height: 0,
			});
			document.body.appendChild(el);
		}
	}

	const $el = $(el);
	el = $el[0];
	if (disabled) {
		return Promise.resolve("disabled");
	}
	let grecaptcha;
	const lang = document.documentElement.getAttribute("lang");
	try {
		grecaptcha = await loadScript(`//www.google.com/recaptcha/api.js?render=explicit&amp;hl=${lang}`, "grecaptcha");
		await new Promise(resolve => grecaptcha.ready(resolve));
	}
	catch {}

	let captchaId = $el.attr("captcha-id");
	let wrapper;
	if (captchaId == null) {
		captchaId = grecaptcha.render(el, {
			sitekey: key,
			size: "invisible",
			type: "image",
			badge: "inline",
			callback: result => callback(result),
		});
		$(el).attr("captcha-id", captchaId);
	}
	else {
		grecaptcha.reset(captchaId);
	}
	const waitForWrapper = setInterval(() => {
		// first we search for recaptcha iframe
		const iframe = $("iframe").filter((idx, iframe) => iframe.src.includes("recaptcha/api2/bframe"));
		[...iframe].some(iframe => {
			const w = $(iframe).closest("body > *");
			// find the corresponding iframe for current captcha
			if (w[0] && !w[0].hasAttribute("captcha-id") || w.attr("captcha-id") == captchaId) {
				w.attr("captcha-id", captchaId);
				wrapper = w; // save iframe wrapper element
				clearInterval(waitForWrapper);
				return true;
			}
		});
	}, 100);
	const result = new Promise((resolve, reject) => {
		callback = (result) => {
			clearInterval(waitForHide);
			resolve(result);
		};
		hideCallback = (result) => {
			clearInterval(waitForHide);
			reject(result);
		};
	});
	grecaptcha.execute(captchaId);
	let shown = false;
	const waitForHide = setInterval(() => {
		if (wrapper) { // if we find iframe wrapper
			if (!shown) {
				// waiting for captcha to show
				if (wrapper.css("visibility") !== "hidden") {
					shown = true;
					console.log("shown");
				}
			}
			else {
				// now waiting for it to hide
				if (wrapper.css("visibility") === "hidden") {
					console.log("hidden");
					hideCallback(new Error("captcha canceled"));
				}
			}
		}
	}, 100);
	return result;

}

export default async function captcha (el, key, disabled) {
	return captchaX({el, key, disabled});
}
