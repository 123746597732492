import {cssGradientToCanvas, splitExpr, getPoint, setPoint} from "#app/common/graphics";
import boxblur from "canvas-box-blur";

function roundRect (canvas, rect, {radius = 0, fill = "white"} = {}) {
	rect.width = (rect.width || canvas.width);
	rect.height = (rect.height || canvas.height);

	const ctx = canvas.getContext("2d");
	radius = doPadding(radius);
	radius = correctRadius(radius, rect.width, rect.height);

	ctx.beginPath();
	ctx.moveTo(rect.left, rect.top);
	ctx.arcTo(rect.right, rect.top, rect.right, rect.bottom, radius[1]);
	ctx.arcTo(rect.right, rect.bottom, rect.left, rect.bottom, radius[2]);
	ctx.arcTo(rect.left, rect.bottom, rect.left, rect.top, radius[3]);
	ctx.arcTo(rect.left, rect.top, rect.right, rect.top, radius[0]);
	ctx.closePath();

	ctx.fillStyle = fill;
	ctx.fill();
}


function correctRadius (r, w, h) {
	if (r[0] + r[1] > w) {
		r[0] -= (r[0] + r[1] - w) / 2;
		r[1] = w - r[0];
	}
	if (r[3] + r[2] > w) {
		r[2] -= (r[2] + r[3] - w) / 2;
		r[3] = w - r[2];
	}
	if (r[0] + r[3] > h) {
		r[0] -= (r[0] + r[3] - h) / 2;
		r[3] = h - r[0];
	}
	if (r[1] + r[2] > h) {
		r[1] -= (r[1] + r[2] - h) / 2;
		r[2] = h - r[1];
	}
	return r;
}

function doPadding (...value) {
	function flattenDeep (array, parent = []) {
		array.reduce((parent, item) => (Array.isArray(item) ? flattenDeep(item, parent) : parent.push(item), parent), parent);
		return parent;
	}
	value = flattenDeep(value);

	switch (value.length) {
		case 0: return new Array(4).fill(0);
		case 1: return new Array(4).fill(value[0] || 0);
		case 2: return [value[0] || 0, value[1] || 0, value[0] || 0, value[1] || 0];
		case 3: return [value[0] || 0, value[0] || 0, value[0] || 0, value[0] || 0];
		case 4: return value.map(i => i || 0);
	}
}


export default function (settings) {
	const canvas = document.createElement("canvas");
	let w = 0;
	let h = 0;
	const blur = settings.blur;
	function update () {
		const ow = canvas.offsetWidth;
		const oh = canvas.offsetHeight;
		if (ow && oh && (ow !== w || oh !== h)) {
			w = ow;
			h = oh;
			canvas.width = w;
			canvas.height = h;

			const ctx = canvas.getContext("2d");
			ctx.clearRect(0, 0, canvas.width, canvas.height);


			// ctx.filter = `blur(${blur}px)`;
			const bl = Math.max(0, Math.min(h, w, blur * 2));
			const gradients = cssGradientToCanvas(canvas, {left: 0, right: 0, width: canvas.width, height: canvas.height}, settings.gradient);
			gradients.forEach(gradient => {
				roundRect(canvas, {left: bl, top: bl, right: canvas.width - bl, bottom: canvas.height - bl}, {radius: settings.radius || 0, fill: gradient});
			});
			boxblur(canvas, blur, {iterations: 2});
		}
	}

	return {canvas, update};
}
