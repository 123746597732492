
const loadScripts = new WeakMap();
export default function (src, exports) {
	let script = document.querySelector(`script[src="${src}"]`);
	let loading;
	if (!script) {
		script = document.createElement("script");
		script.setAttribute("data-dynamic-script", "loading");
		loading = new Promise((resolve, reject) => {
			script.addEventListener("load", event => {
				script.setAttribute("data-dynamic-script", "ready");
				if (exports) {
					if (Array.isArray(globalThis[exports])) {
						resolve(exports.map(key => globalThis[key]));
					}
					else {
						resolve(globalThis[exports]);
					}
				}
				else {
					resolve();
				}
			});
			script.addEventListener("error", event => {
				script.remove();
				reject();
			});
			script.src = src;
			document.querySelector("head").appendChild(script);
		});
		loadScripts.set(script, loading);
		return loading;
	}
	loading = loadScripts.get(script);
	return loading;
}
