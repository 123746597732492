import {flattenDeep} from "#app/common/utils.mjs";

export function createCanvas (width, height) {
	const canvas = document.createElement("canvas");
	canvas.width = width;
	canvas.height = height;
	return canvas;
}

export function getContrast (color, threshold = 130) {
	const hexVal = colorToArray(color);
	const [r, g, b] = hexVal;
	const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
	return brightness > threshold ? "#000000" : "#ffffff";
}

export function splitExpr (str, split = ",") {
	const open = `(,[,",'`.split(",");
	const close = `),],",'`.split(",");
	const isolated = `",'`.split(",");

	const result = [];
	let resi = 0;
	let i = 0;
	const openStack = [];
	while (i < str.length) {
		const c = str[i];
		if (!openStack.length && c === split) {
			resi++;
		}
		else {
			result[resi] = (result[resi] || "") + c;
		}

		if (openStack.length && isolated.includes(openStack[openStack.length - 1])) {
			if (openStack[openStack.length - 1] === c) {
				openStack.pop();
			}
		}
		else {
			if (open.includes(c)) {
				openStack.push(c);
			}
			else if (close.includes(c) && openStack.length && openStack[openStack.length - 1] === open[close.indexOf(c)]) {
				openStack.pop();
			}
		}
		i++;
	}
	return result.map(v => (v || "").trim());
}


export function cssGradientToCanvas (canvas, rect, str) {
	const gradients = splitExpr(str).reverse();
	return gradients.map(str => {
		const [all, type, paramsStr] = str.match(/^(linear-gradient|radial-gradient)\((.*?)\)$/);

		let params = splitExpr(paramsStr);

		let deg = 0;
		if (params[0].startsWith("to ")) {
			const p = params[0];
			const x = p.includes("left") ? -1 : p.includes("right") ? 1 : 0;
			const y = p.includes("bottom") ? -1 : p.includes("top") ? 1 : 0;
			deg = Math.atan2(x, y) * 180 / Math.PI;
			params = params.slice(1);
		}
		else if (params[0].match(/\ddeg/)) {
			deg = +params[0].replace("deg", "");
			params = params.slice(1);
		}
		else if (params[0].match(/\dturn/)) {
			deg = +params[0].replace("turn", "") * 360;
			params = params.slice(1);
		}
		else if (params[0].match(/\drad/)) {
			deg = +params[0].replace("rad", "") * 180 / Math.PI;
			params = params.slice(1);
		}
		else if (params[0].match(/\dgrad/)) {
			deg = +params[0].replace("grad", "") / 400 * 360;
			params = params.slice(1);
		}

		const width = rect.width == null ? rect.right - rect.left : rect.width;
		const height = rect.height == null ? rect.bottom - rect.top : rect.height;
		const radian = (deg + 180) * Math.PI / 180;
		const lineLength = Math.abs(width * Math.sin(radian)) + Math.abs(height * Math.cos(radian));
		const halfWidth = width / 2;
		const halfHeight = height / 2;
		const halfLineLength = lineLength / 2;

		const from = {
			x: halfWidth + Math.sin(radian) * halfLineLength,
			y: halfHeight - Math.cos(radian) * halfLineLength,
		};
		const to = {
			x: width - from.x,
			y: height - from.y,
		};

		let minPos;
		let maxPos;
		params = params.map((param, idx) => {
			let [value, pos] = param.split(/\s+/);
			if (pos == null) {
				if (idx === 0) {
					pos = 0;
				}
				else if (idx === params.length - 1) {
					pos = 1;
				}
			}
			else {
				pos = +(pos.replace("%", "").trim()) / 100;
			}
			minPos = minPos == null ? pos : Math.min(minPos, pos);
			maxPos = maxPos == null ? pos : Math.max(maxPos, pos);

			return {value, pos};
		});

		const ctx = canvas.getContext("2d");
		const gradient = ctx.createLinearGradient(from.x, from.y, to.x, to.y);
		let startPosIdx = 0;
		params.forEach((param, idx) => {
			if (param.pos == null) {
				let endPosIdx;
				for (let i = idx + 1; i < params.length; i++) {
					if (params[i].pos != null) {
						endPosIdx = i;
						break;
					}
				}
				for (let i = idx; i < endPosIdx; i++) {
					params[i].pos = params[startPosIdx].pos + ((i - startPosIdx) / (endPosIdx - startPosIdx)) * (params[endPosIdx].pos - params[startPosIdx].pos);
				}
			}
			else {
				startPosIdx = idx;
			}
			param.pos = Math.round(param.pos * 1000000) / 1000000;
			gradient.addColorStop(param.pos, param.value);

		});

		return gradient;
	});
}

export function toDPR (canvas, srcDpr = 1, destDpr = 1) {
	const w = (canvas.width / srcDpr) * destDpr;
	const h = (canvas.height / srcDpr) * destDpr;
	const dst = createCanvas(w, h);
	const ctx = dst.getContext("2d");
	ctx.drawImage(canvas, 0, 0, w, h);
	return dst;
}

export function colorDistance (v1, v2) {
	let i;
	let d = 0;
	for (i = 0; i < v1.length; i++) {
		d += Math.pow(v1[i] - v2[i], 2);
	}

	const result = Math.sqrt(d);
	return result;
}

export function colorAvg (colors) {
	const acc = colors.reduce((acc, i) => {
		for (let n = 0; n < 4; n++) {
			acc[n] += i[n];
		}
		return acc;
	}, [0, 0, 0, 0]);
	for (let n = 0; n < 4; n++) {
		acc[n] = Math.round(acc[n] / colors.length);
	}
	return acc;
}

export const getPoint = (x, y, imageData, alpha = true) => {
	const data = imageData.data;
	const i = (y * imageData.width * 4) + (x * 4);
	if (alpha) {
		return [
			data[i + 0],
			data[i + 1],
			data[i + 2],
			data[i + 3],
		];
	}
	else {
		return [
			data[i + 0],
			data[i + 1],
			data[i + 2],
		];
	}
};

export const setPoint = (x, y, imageData, rgba) => {
	const data = imageData.data;
	const i = (y * imageData.width * 4) + (x * 4);
	data[i + 0] = rgba[0];
	data[i + 1] = rgba[1];
	data[i + 2] = rgba[2];
	data[i + 3] = rgba[3];
};


let colorToArrayCanvas;
let colorToArrayCtx;
const colorToArrayCache = {};
export const colorToArray = (color) => {
	if (colorToArrayCache[color]) {
		return colorToArrayCache[color];
	}
	colorToArrayCanvas = colorToArrayCanvas || createCanvas(1, 1);
	colorToArrayCtx = colorToArrayCtx || colorToArrayCanvas.getContext("2d");
	colorToArrayCtx.fillStyle = color;
	colorToArrayCtx.fillRect(0, 0, 1, 1);
	const result = [...getImageData(colorToArrayCanvas).data];
	colorToArrayCache[color] = result;
	return result;
};

export function getCrop (imageData, gap = 0) {
	const w = imageData.width;
	const h = imageData.height;
	let x1 = w;
	let x2 = 0;
	let y1 = h;
	let y2 = 0;
	for (let y = 0; y < h; y++) {
		for (let x = 0; x < w; x++) {
			if (getPoint(x, y, imageData)[3] > 50) {
				if (y < y1) {y1 = y;}
				if (y > y2) {y2 = y;}
				if (x < x1) {x1 = x;}
				if (x > x2) {x2 = x;}
			}
		}
	}
	const cropData = {
		left: Math.max(0, x1 - gap),
		top: Math.max(0, y1 - gap),
		right: Math.min(w, x2 + gap * 2),
		bottom: Math.min(h, y2 + gap * 2),
	};
	cropData.width = cropData.right - cropData.left;
	cropData.height = cropData.bottom - cropData.top;
	return cropData;
}

export function imageToCanvas (img, dpr = 1) {
	const w = img._width == null ? img.width : img._width;
	const h = img._height == null ? img.height : img._height;
	const canvas = createCanvas(w * dpr, h * dpr);
	const ctx = canvas.getContext("2d");
	ctx.drawImage(img, 0, 0, w * dpr, h * dpr);
	return canvas;
}

export function getImageData (img) {
	const canvas = imageToCanvas(img);
	const ctx = canvas.getContext("2d");
	return ctx.getImageData(0, 0, canvas.width, canvas.height);
}

export function copyCanvas (canvas) {
	const c = createCanvas(canvas.width, canvas.height);
	c.getContext("2d").drawImage(canvas, 0, 0);
	return c;
}

export function doPadding (...value) {
	value = flattenDeep(value);
	if (value.length === 1 && typeof value[0] === "string") {
		value = value[0].split(/\s/g).map(i => +i);
	}
	value = value.map(i => i || 0);

	switch (value.length) {
		case 0: return new Array(4).fill(0);
		case 1: return new Array(4).fill(value[0] || 0);
		case 2: return [value[0] || 0, value[1] || 0, value[0] || 0, value[1] || 0];
		case 3: return [value[0] || 0, value[0] || 0, value[0] || 0, value[0] || 0];
		case 4: return value.map(i => i || 0);
	}
}

export async function loadImage (url, {width, height} = {}) {
	const img = document.createElement("img");
	// img.crossOrigin = "anonymous";
	img.src = url;

	return new Promise(resolve => {
		img.onload = () => {
			img.width = img.naturalWidth;
			img.height = img.naturalHeight;
			if (width) {
				img.width = width;
				if (!height) {
					img.height = Math.floor(img.naturalHeight * (width / img.naturalWidth));
				}
			}
			if (height) {
				img.height = height;
				if (!width) {
					img.width = Math.floor(img.naturalWidth * (height / img.naturalHeight));
				}
			}
			resolve(img);
		};
	});
}

export async function canvasToFile (canvas, filename) {
	return await new Promise(resolve => canvas.toBlob(blob => resolve(new File([blob], filename, {type: "image/png", lastModified: Date.now()})), "image/png"));
}
